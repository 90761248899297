import React, { useState } from 'react';
import { Detector } from 'react-detect-offline';
import { useDispatch, useSelector } from 'react-redux';
import { db, selectedBase } from '../core/Firebase/firebase';
import { useDocs } from '../core/Firebase/FirebaseHooks';
import {customToast, showMyProfil} from '../core/js/backoffice';
import { selectAuthState, switchTheme } from '../core/Redux/features/user';
import MyProfil from './MyProfil';
import NotifsList from './NotifsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
const Shortcuts = () => {
    const dispatch = useDispatch();
    const authState = useSelector(selectAuthState);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {data:notifs} = useDocs({
        db:db,
        whereToOrderBy:"createdAt",
        collectionName:authState["currentUser"] ? authState["currentUser"]["id"] ? "users_"+selectedBase+"/"+authState["currentUser"]["id"]+"/notifications" : "":""
    })
    return (
        <div>
            <div className="shortcuts-fixed" id="shortcuts-fixed">
                <div className="shortcut-card">
                    <div className="c-flexed jc-btwn-col">
                        <div className="shortcut-item-card" onClick={handleShow} /* onClick={showNotifications} */>
                            <div className="r-flexed">
                                <img alt="" src="/assets/images/ico/ic_alert.svg"/>
                                <span className="o-badge">{notifs.filter((item)=>((item["isArchived"] === false || item["isArchived"] === undefined) && item["isViewed"] === false)).length}</span>
                            </div>
                        </div>
                        <Detector render={({online})=>(
                            <div className="shortcut-item-card" onClick={online ? showMyProfil : ()=>{
                               //Mettre Un Toast pour informer au user qu'il n'a pas acces a Internet 
                               customToast({
                                message:"Vous n'avez pas accès à internet",
                                position:"top-center",
                                duration: 4000,
                                className:"mt-100",
                                icon:<span class="text-warning"><FontAwesomeIcon icon={faWarning} /></span>
                            })
                            }}>
                            <img alt="" style={{width: "20px"}} src="/assets/images/ico/ic_person.svg"/>
                        </div>
                        )}/>
                        
                        <div className="shortcut-item-card" onClick={()=>{dispatch(switchTheme())}}>
                            <img alt="" src={authState.theme !== true ? "/assets/images/ico/ic_moon.svg":"/assets/images/ico/ic_lumiere.svg"}/>
                        </div>
                    </div>
                </div>
            </div>
            <MyProfil notifs={notifs.filter((item)=>((item["isArchived"] === false || item["isArchived"] === undefined) && item["isViewed"] === false))}/>
            {show&&<NotifsList notifs={notifs.filter((item)=>(item["isArchived"] === false || item["isArchived"] === undefined))} handleClose={handleClose}n show={show} />}
        </div> 
    );
}


export default Shortcuts;
