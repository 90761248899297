import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canModule, customDateFormat } from "../../core/js/backoffice";
import {
  closeModalAdd,
  selectShowModal,
  showModalAdd,
} from "../../core/Redux/features/user";
import Filtres from "../../partials/FrequentlyUsed/Filtres";
import TitleBtnAdd from "../../partials/FrequentlyUsed/TitleBtnAdd";
import { deleteData } from "../../core/Firebase/services/FirebaseService";
import {
  useDocs,
  useTotalSize,
  useWhereDocs_1,
} from "../../core/Firebase/FirebaseHooks";
import { db } from "../../core/Firebase/firebase";
import Paginations from "../../partials/FrequentlyUsed/Paginations";
import { Modals } from "../../partials/modals/Modals";
import { ModalCouleur } from "./modals/ModalCouleur";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { SpeedDial, Tooltip } from "primereact";

export default function ThemesNew() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mounted = useRef();
  const speedRef = useRef([]);
  const show = useSelector(selectShowModal);
  const [isAdd, setIsAdd] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(0);
  const [isPaginated, setIsPaginated] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(undefined);
  const [dataType] = useState("mode");
  const [count, setCount] = useState(10);
  const [foreignMap, setForeignMap] = useState({});
  const { numberOfData: totalSize } = useTotalSize({
    db: db,
    collectionName: dataType,
  });
  var { data: tabModes,numberOfData: totalItems  } = useDocs({
    db: db,
    converterName: dataType,
    sort: "desc",
    whereToOrderBy: "createdAt",
    count: count,
    isPaginated: isPaginated,
    page: nextPage,
  });
  
  const mapFiltersTab = [
    { isField: true, name: "Désignation", key: "designation" },
    //{isField: true, name: "Description", key: "description"+langueToFilter}
  ];
  const [mapValues, setMapValues] = useState({});
  var { data: tabValues} = useWhereDocs_1({
    db: db,
    collectionName: dataType,
    mapValues: mapValues,
  });

  //const [tabValues, setTabValues] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    if (!mounted.current) {
      //did mounted
      document.title = "Modes";
      mounted.current = true;
    } else {
      //did update
    }
  });

  useEffect(() => {
    console.log("ca filtre",foreignMap);
  },[foreignMap]);

  /*  const handleSort = () => {
         setSort(!sort);
     } */
  useEffect(
    () => {
      //console.log(testWhere);
      /* if(itemToUpdate !== undefined && isFiltering) {
            let c = tabThemes.filter((i)=> i['id'] === itemToUpdate['id']);
            let cc = tabValues.findIndex((i)=> i['id'] === itemToUpdate['id']);
            if(c.length > 0 && cc !== -1){
                tabValues[cc] = c[0];
                setTabValues([...tabValues]);
            }
            
        } */
    },
    [
      /* tabThemes */
    ]
  );

  const handleSelectChange = (value) => {
    setIsPaginated(false);
    setCurrentPage(1);
    if (value > 0) {
      setCount(value);
    } else if (value === "0") {
      setCount(10);
    }
  };
  const handlePageChange = (pageNumber) => {
    if (totalSize / count >= 1) {
      setCurrentPage(pageNumber);
      setIsPaginated(true);
      setNextPage((pageNumber - 1) * totalItems + 1);
    }
  };

  const handleFilter = (mapValues) => {
    /*  let cc = [];
         cc = genericFilter({tabItems: tabThemes, mapValues: mapValues, mapFiltersTab: mapFiltersTab});

         setTabValues(cc);
         */
      setForeignMap({});

    setIsFiltering(true);
    setMapValues(mapValues);
  };
  const cancelFilter = () => {
    //setTabValues([]);
    setMapValues({});
    setIsFiltering(false);
    handleSelectChange(10);
  };
  const redirectToDetail = (value) => {
    if (value && value["id"]) {
      navigate("/backoffice/themes/detailTheme/" + value["id"]);
    }
  };

  return (
    canModule(["list-mode"]) && (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <TitleBtnAdd
          showBtn={canModule(["create-mode"])}
          marginTop="mt-20"
          icon="/assets/images/ico/icon-color.svg"
          btnClick={() => {
            setIsAdd(true);
            setItemToUpdate(undefined);
            //setIsPaginated(false);
            dispatch(showModalAdd());
          }}
          itemsName="Modes"
          count={!isFiltering ? totalSize : tabValues.length}
        />

        <Filtres
          cancelFilter={cancelFilter}
          tabFilters={mapFiltersTab}
          handleSubmit={handleFilter}
          marginTop="mt-30"
        />

        <section className="mt-30">
          <div className="card-table pt-20 pb-20 pl-20 pr-20">
            <table className="table basic-table table-borderless table-striped table-responsive table-hover">
              <thead className="thead-table">
                <tr>
                  <th>Désignation du mode</th>
                  <th>Couleur</th>
                  {/* <th>Couleur d'arrière plan pied de page</th>
                  <th>Couleur de selection</th>
                  <th>Couleur de déselection</th>
                  <th>Couleur primaire</th>
                  <th>Couleur secondaire</th> */}
                  <th>Statut</th>
                  {canModule(["detail-mode", "delete-mode", "update-mode"]) && (
                    <th className="">
                      <div className="d-flex jc-center">
                        <img
                          className=""
                          alt=""
                          src="/assets/images/ico/ic_settings.png"
                        />
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!isFiltering &&
                  tabModes.map((item) => {
                    //console.log(item.date.toString());
                    return newFunction(item);
                  })}
                {isFiltering &&
                  tabValues.map((item) => {
                    //console.log(item.date.toString());
                    return newFunction(item);
                  })}
              </tbody>
            </table>
          </div>
        </section>

        <section className="mt-30">
          {!isFiltering && (
            <Paginations
              activePage={currentPage}
              totalItemsCount={totalSize}
              itemsCountPerPage={totalItems}
              handleSelectChange={(event) => {
                handleSelectChange(event.target.value);
              }}
              handlePageChange={handlePageChange}
            />
          )}
          {/*
            {isFiltering && <Paginations activePage={currentPage} totalItemsCount={tabValues.length} itemsCountPerPage={totalItems}
                             handleSelectChange={(event) => {
                                 handleSelectChange(event.target.value)
                             }} handlePageChange={handlePageChange}/>} */}
        </section>

        <Modals
          modalTitle="Mode"
          titleIcon="/assets/images/ico/icon-color.svg"
          modalBody={
            <ModalCouleur
              isAdd={isAdd} /* langues={langues} */
              itemToUpdate={itemToUpdate}
            />
          }
          show={show}
          handleClose={() => {
            dispatch(closeModalAdd());
          }}
        />
      </motion.div>
    )
  );

  function newFunction(item) {
    if (!foreignMap[item.id])
    {
      setForeignMap({ ...foreignMap, [item.id]: "refresh" });
    }
    return (
      <tr key={item.id}>
        <td>{item.designation}</td>
        <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.couleurHEX,
            }}
          ></div>
          <span>{item.couleurHEX}</span>
        </td>
        {/* <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.bottombackgroundColor,
            }}
          ></div>
          <span>{item.bottombackgroundColor}</span>
        </td>
        <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.selectedColor,
            }}
          ></div>
          <span>{item.selectedColor}</span>
        </td>
        <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.unSelectedColor,
            }}
          ></div>
          <span>{item.unSelectedColor}</span>
        </td>
        <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.onPrimary,
            }}
          ></div>
          <span>{item.onPrimary}</span>
        </td>
        <td>
          <div
            style={{
              width: "100%",
              height: "10px",
              marginTop:'.5em',
              borderRadius: "4px",
              background: item.secondary,
            }}
          ></div>
          <span>{item.secondary}</span>
        </td> */}

        <td>{item.isDefault ? "Couleur par défaut" : "Standars"}</td>
        {canModule(["detail-mode", "delete-mode", "update-mode"]) && (
          <td>
            <div className="d-flex jc-center">
              <SpeedDial
                direction="left"
                showIcon="pi pi-bars"
                hideIcon="pi pi-times"
                buttonClassName="wdpx-35 hpx-35 p-button-outlined p-button-success align-self-center"
                buttonStyle={{ border: "1px solid #337f77" }}
                id={item.id}
                ref={(el) => (speedRef.current[item.id] = el)}
                style={{ position: "relative" }}
                // className={
                //   speedRef.current[item.id]?.props?.id === item.id
                //     ? "myCustomHamburger1"
                //     : "myCustomHamburger1"
                // }
                className="myCustomHamburger1"
                model={getMenuItem({ data: item })}
              />
            </div>
          </td>
        )}
      </tr>
    );
  }

  function getMenuItem({ data }) {
    let result = [];
    let items = [
      {
        id: 1,
        label: "Modifier",
        icon: "pi pi-pencil",
        command: () => {
          setIsAdd(false);
          setItemToUpdate(data);
          setIsPaginated(false);
          dispatch(showModalAdd());
        },
        template: (u, options) => {
          return (
            <div
              data-pr-tooltip={u.label}
              className={
                options.className +
                " bg-secondary wdpx-35 hpx-35 cursor-pointer myTooltip1"
              }
              onClick={options.onClick}
            >
              <span className={u.icon + " text-white"}></span>
              <Tooltip
                position="bottom"
                className="fsize-12"
                target=".myTooltip1"
              />
            </div>
          );
        },
      },
      {
        id: 2,
        label: "Supprimer",
        icon: "pi pi-trash",
        command: () => {
          deleteData({ dataType: "mode", id: data.id });
        },
        template: (u, options) => {
          return (
            <div
              data-pr-tooltip={u.label}
              className={
                options.className +
                " bg-danger wdpx-35 hpx-35 cursor-pointer myTooltip3"
              }
              onClick={options.onClick}
            >
              <span className={u.icon + " text-white"}></span>
              <Tooltip
                position="bottom"
                className="fsize-12"
                target=".myTooltip3"
              />
            </div>
          );
        },
      },
    ];
    if (canModule(["update-mode"])) {
      result.push(items.filter((i) => i.id === 1)[0]);
    }
    if (canModule(["delete-mode"])) {
      result.push(items.filter((i) => i.id === 2)[0]);
    }
    return result;
  }
}
