import React, { useEffect, useRef, useState } from "react";
import CardPublications from "../../partials/FrequentlyUsed/CardPublications";
import Filtres from "../../partials/FrequentlyUsed/Filtres";
import Paginations from "../../partials/FrequentlyUsed/Paginations";
import TitleBtnAdd from "../../partials/FrequentlyUsed/TitleBtnAdd";
import { Modals } from "../../partials/modals/Modals";
import "react-datepicker/dist/react-datepicker.css";
import { canModule } from "../../core/js/backoffice";
import { useDispatch, useSelector } from "react-redux";
import { db, selectedBase } from "../../core/Firebase/firebase";
import {
  useDocs,
  useTotalSize,
  useWhereDocs_1,
} from "../../core/Firebase/FirebaseHooks";
import {
  closeModalAdd,
  selectShowModal,
  showModalAdd,
} from "../../core/Redux/features/user";
import { ModalPubBody } from "./modals/ModalPublication";
import DetailPublication from "./modals/DetailPublication";
import { motion } from "framer-motion";
import algoliaSearch from "algoliasearch";

export default function Publications() {
  const dispatch = useDispatch();
  const mounted = useRef();
  const show = useSelector(selectShowModal);
  const [isAdd, setIsAdd] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(0);
  const [isPaginated, setIsPaginated] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(undefined);
  const [dataType] = useState("publications");
  const [count, setCount] = useState(10);
  const [isFiltering, setIsFiltering] = useState(false);
  const [mapValues, setMapValues] = useState({});
  const [selectedSize, setSelectedSize] = useState(0);
  const [searchClients, setSearchClients] = useState();
  const client = algoliaSearch(
    "UXPOT4CE0L",
    "5510ca81827f20f0b8b8fdc155b3853e"
  );
  const index = client.initIndex("publications_" + selectedBase);
  
  let langueToFilter = ".fr_FR";

  const { numberOfData: totalSize } = useTotalSize({
    db: db,
    collectionName: dataType,
  });
  var { data: publications, numberOfData: totalItems } = useDocs({
    db: db,
    converterName: dataType,
    sort: "desc",
    whereToOrderBy: "createdAt",
    count: count,
    isPaginated: isPaginated,
    page: nextPage,
  });

  const mapFiltersTab = [
    { isDate: true, name: "Date Début", key: "createdAt" },
    { isDate: true, name: "Date Fin", key: "createdAtEnd" },
    {
      isField: true,
      name: "Mots clés, titres, désignations...",
      key: "motcles",
    },
    {
      isDropdown: true,
      key: "disabled",
      name: "Approuvée",
      placeholder: "Approuvée",
      tabDropdown: ["Oui", "Non"],
    },
    {
      isDropdown: true,
      key: "type",
      name: "Type",
      placeholder: "Type",
      tabDropdown: ["doua", "image", "audio", "video"],
    },
  ];

  // var {data: tabValues} = useWhereDocs_1({
  //     db: db,
  //     collectionName: dataType,
  //     mapValues: mapValues
  // });

  var [tabValues, setTabValues] = useState([]);

  useEffect(() => {
    if (!mounted.current) {
      //did mounted
      document.title = "Publications";
      dispatch(closeModalAdd());
      mounted.current = true;
    } else {
      //did update
    }
  });

  const updatePub = (item) => {
    //console.log(item);
    setIsAdd(false);
    setItemToUpdate(item);
    setIsPaginated(false);
    dispatch(showModalAdd());
  };

  const handleSelectChange = (value) => {
    setIsPaginated(false);
    setCurrentPage(1);
    if (value > 0) {
      setCount(value);
    } else if (value === "0") {
      setCount(10);
    }
  };
  const handlePageChange = (pageNumber) => {
    if (totalSize / count >= 1) {
      setCurrentPage(pageNumber);
      setIsPaginated(true);
      setNextPage((pageNumber - 1) * totalItems + 1);
    }
  };

  const convertToLowerCase = (value) => {
    return value.toLowerCase();
  };

  const execFiltre = (newMapValues, publications) => {
    const pubFiltrer = [];

    publications.forEach((pub) => {
      let test = false;
      //dateDeb & dateFin
      if (
        newMapValues.createdAt !== undefined ||
        newMapValues.createdAtEnd !== undefined
      ) {
        if (
          newMapValues.createdAt !== undefined &&
          newMapValues.createdAtEnd !== undefined
        ) {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            Date.parse(pub.date) <= newMapValues.createdAtEnd
          ) {
            test = true;
          }
        } else {
          if (
            Date.parse(pub.date) >= newMapValues.createdAt &&
            newMapValues.createdAt !== undefined
          ) {
            test = true;
          }
          if (Date.parse(pub.date) <= newMapValues.createdAtEnd) {
            test = true;
          }
        }
      } else {
        test = true;
      }

      if (newMapValues["disabled"] === "Oui") {
        if (pub.disabled !== false) {
          test = false;
        }
      } else if (newMapValues["disabled"] === "Non") {
        if (pub.disabled !== true) {
          test = false;
        }
      }

      if (newMapValues["type"] !== undefined) {
        console.log(newMapValues["type"]);
        
        if (pub.type !== newMapValues["type"]) {
          test = false;
        }
      }

      if (test) {
        pubFiltrer.push(pub)
      }
    });

    setIsFiltering(true);
    setTabValues(pubFiltrer);
  };

  const handleFilter = (mapValues) => {
    let newMapValues = Object.fromEntries(Object.entries(mapValues));    

    if (
      newMapValues.motcles !== undefined &&
      newMapValues.motcles !== ""
    ) {
      
      console.log("enter");
      index.search(newMapValues.motcles).then(({ hits }) => {
        if (hits.length > 0) {
          let stock = [];
          hits.forEach((el) => {
            publications.forEach((pub) => {
              if (el.id === pub.id) {
                stock.push(pub);
              }
            });
          });
          execFiltre(newMapValues, stock);
        }
      });
      
    } else {
      console.log("outed");
      execFiltre(newMapValues, publications);
    }
  };

  const cancelFilter = () => {
    setIsFiltering(false);
  };

  return (
    canModule(["list-posts"]) && (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* <!-- Dashboard Content --> */}
        <TitleBtnAdd
          showBtn={canModule(["create-posts"])}
          marginTop="mt-20"
          icon="/assets/images/ico/pub_modals.svg"
          btnClick={() => {
            setIsAdd(true);
            setItemToUpdate(undefined);
            //setIsPaginated(false);
            dispatch(showModalAdd());
          }}
          itemsName="Publications"
          count={!isFiltering ? totalSize : tabValues.length}
        />

        <Filtres
          cancelFilter={cancelFilter}
          tabFilters={mapFiltersTab}
          handleSubmit={handleFilter}
          marginTop="mt-30"
        />

        <section className="mt-30">
          <div className="row">
            {!isFiltering &&
              publications.map((item) => {
                return newFunction(item);
              })}
            {isFiltering &&
              tabValues.map((item) => {
                //console.log(item.date.toString());
                return newFunction(item);
              })}
          </div>
        </section>

        <section className="mt-30">
          {!isFiltering && (
            <Paginations
              pageSize={[0, 4, 8, 24, 50, 100]}
              activePage={currentPage}
              totalItemsCount={totalSize}
              itemsCountPerPage={totalItems}
              selectedSize={selectedSize}
              handleSelectChange={(event) => {
                handleSelectChange(event.target.value);
                setSelectedSize(event.target.value);
              }}
              handlePageChange={handlePageChange}
            />
          )}
        </section>

        {/* Modals Profil Client */}
        <Modals
          size="xl"
          modalTitle="Publication"
          titleIcon="/assets/images/ico/pub_modals.svg"
          modalBody={<ModalPubBody isAdd={isAdd} itemToUpdate={itemToUpdate} />}
          show={show}
          handleClose={() => {
            dispatch(closeModalAdd());
          }}
        />

        {showDetail && (
          <DetailPublication
            show={showDetail}
            item={itemToUpdate}
            handleClose={() => {
              setShowDetail(false);
            }}
          />
        )}
      </motion.div>
    )
  );

  function newFunction(item) {
    return (
      <div
        key={item.id}
        className="mb-30 col-12 col-sm-6 col-md-6 col-lg-3 col-pub"
      >
        <CardPublications
          isFiltering={isFiltering}
          onUpdate={() => {
            updatePub(item);
          }}
          item={item}
        />
      </div>
    );
  }
}
